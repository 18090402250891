import { ReactNode } from 'react';

import { Box } from 'components/Box';
import { AnimatedCheckmark } from 'components/Icon/AnimatedCheckmark';
import { Body } from 'components/Text/Body';
import { TabContext } from 'containers/TabContext';
import { legacySpace } from 'theme/theme';

import {
  CheckboxHolder,
  CheckmarkHolder,
  StyledCheckbox,
  StyledLabel,
  Toggle,
  ToggleCheckmarkHolder,
} from './Checkbox.styled';

type Props = {
  'aria-label'?: string;
  'aria-labelledby'?: string;
  'data-qa-id'?: string;
  id?: string;
  name?: string;
  label?: ReactNode;
  placeholder?: string | null | undefined;
  required?: boolean;
  value?: (boolean | null | undefined) | (string | null | undefined);
  checked: boolean;
  hasError?: boolean;
  onChange: (checked: boolean) => void;
  labelPlacement?: 'left' | 'right';
  toggle?: boolean;
  disabled?: boolean;
  large?: boolean;
  className?: string;
};

export function Checkbox({
  id,
  name,
  label,
  checked,
  hasError,
  onChange,
  toggle,
  labelPlacement = 'right',
  disabled,
  large,
  className,
  value,
  ...props
}: Props) {
  return (
    <CheckboxHolder $hasError={hasError} className={className}>
      {label && labelPlacement === 'left' ? (
        <StyledLabel htmlFor={id}>
          <Body lineHeight={1.4}>{label}</Body>
        </StyledLabel>
      ) : null}
      {toggle ? (
        <Box
          lineHeight={0}
          position="relative"
          width={40}
          height={24}
          flex="0 0 auto"
        >
          <Toggle $checked={checked} $disabled={disabled}>
            <ToggleCheckmarkHolder>
              {checked && <AnimatedCheckmark size={16} colorName="brandBlue" />}
            </ToggleCheckmarkHolder>
          </Toggle>
        </Box>
      ) : (
        <Box
          position="relative"
          width={large ? 24 : 16}
          height={large ? 24 : 16}
          flex="0 0 auto"
          marginTop={`${large ? legacySpace[2] : legacySpace[1]}px`}
        >
          <CheckmarkHolder
            $checked={checked}
            $disabled={disabled}
            className="checkmark-container"
          >
            {checked && <AnimatedCheckmark size={16} colorName="white" />}
          </CheckmarkHolder>
        </Box>
      )}
      <TabContext.Consumer>
        {(isTabbing) => (
          <StyledCheckbox
            // @ts-expect-error TS(2769): No overload matches this call.
            value={value || 'on'}
            $isTabbing={isTabbing}
            type="checkbox"
            name={name}
            id={id}
            checked={checked}
            onChange={(e) => {
              e.stopPropagation();
              onChange(e.target.checked);
            }}
            disabled={disabled}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        )}
      </TabContext.Consumer>
      {label && labelPlacement === 'right' ? (
        <StyledLabel htmlFor={id}>
          <Body lineHeight={1.4}>{label}</Body>
        </StyledLabel>
      ) : null}
    </CheckboxHolder>
  );
}
