import { ReactNode } from 'react';

import { Checkbox } from 'components/Checkbox';
import { LargeCheckbox } from 'components/LargeCheckbox';
import { invert } from 'utils/functional';

import { Field } from './Field/Field';

type Props = {
  'data-qa-id'?: string;
  name: string;
  label?: ReactNode;
  description?: string;
  disabled?: boolean;
  toggle?: boolean;
  // An optional map of stored form value to checked state
  // Useful if you want to submit something other than true / false
  // E.g., {STATE1: true, STATE2: false}. Checked corresponds to
  // the string 'STATE1'
  // TODO: use ES6 map to extend past strings / avoid type issues
  valueToChecked?: Record<string, boolean>;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...args: Array<any>) => any;
  value?: boolean;
  large?: boolean;
  fullWidth?: boolean;
  noMargin?: boolean;
  labelPlacement?: 'left' | 'right';
};

export function CheckboxField({
  name,
  label,
  labelPlacement = 'right',
  description,
  onChange,
  large,
  fullWidth,
  ...props
}: Props) {
  return (
    <Field
      inputProps={props}
      name={name}
      description={description}
      renderInput={({ value, id, qaId, hasError, updateValue }) => {
        const { valueToChecked, ...otherProps } = props;
        const trueFalseMap = valueToChecked && invert(valueToChecked);
        const checked =
          (valueToChecked ? valueToChecked[value] : value) || false;

        const CheckboxComponent = large ? LargeCheckbox : Checkbox;

        return (
          <CheckboxComponent
            id={id}
            name={name}
            labelPlacement={labelPlacement}
            label={label}
            checked={checked}
            hasError={hasError}
            onChange={(v) => {
              if (trueFalseMap) {
                updateValue(trueFalseMap[v.toString()]);
              } else {
                updateValue(v);
              }

              if (onChange) {
                onChange(v);
              }
            }}
            data-qa-id={qaId}
            fullWidth={fullWidth}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
          />
        );
      }}
    />
  );
}
